import React  from 'react';
import CartItemsList from './CartItemList';



const CartSidebar = ({ cartItems, onRemoveItem, onCheckout  ,  addToCart , showCart , setShowCart }) => {


  const subtotal = cartItems.reduce((acc, item) => acc + (item.quantity * item.price), 0);

  return (

    <div>
      { showCart && (
      <div className="cart-sidebar">

      <span className="close" onClick={() => setShowCart(!showCart)}> &times;</span>

      <div className="cart-header">Crackers Cart</div>
      <div className="cart-header"> Total Items {cartItems.length}</div>
      {cartItems.length === 0 ? (
        <div className="empty-cart">Your cart is empty</div>
      ) : (
        <div>
          <CartItemsList addToCart={addToCart} cartItems={cartItems} onRemoveItem={onRemoveItem}/>
          <div className="cart-subtotal">
            Subtotal: &#8377; {subtotal}
          </div>

          
          <button  className="checkout-button" onClick={() => {
            onCheckout(subtotal)
          }}>
            Checkout
          </button>
        </div>
      )}
    </div>
    )} 
    </div>
    
    
  );


};

export default CartSidebar;
