import React  from 'react';
import cart from './cart.svg'

const Header = ({cartcount, cartDisp , searchKey, onSearchChange , onSearchClick , onLogoClick }) => {

  let textInput = React.createRef();
  
  return (
    <div className="header">
      {/* Logo */}
      <div className="logo"  onClick={() => onLogoClick('')}>
        <div style={{fontWeight: '800', fontSize:"24px" , color: 'gold'}}> PATAKI <div style={{paddingLeft:'80px'}}>  ANGADI</div></div>
        <div style={{paddingLeft:"20px" , paddingTop:'4px' , fontSize:"14px" , color:'#252324' , fontStyle:"italic" , fontWeight:'250' }}> lighting the sky </div>
      </div>

      {/* Search Bar */}
      <div className="search-bar">
        <input type="text" ref={textInput} value={searchKey}  onChange={onSearchChange} placeholder="Search crackers" />
        <button onClick={() => onSearchClick(textInput)}>Search</button>
      </div>
 
      {/* Cart Icon */}
      <div className="cart-icon">
        
        <img onClick={cartDisp} alt='hello' width={"48px"} height={'48px'} src={cart}>
        </img>
        <i className="fa fa-shopping-cart"></i>
        <span className="cart-item-count">{cartcount}</span>
      </div>

      

      
     
    </div>
  );
};

export default Header;
