import './App.css';
import Header from './Header';
import CartSidebar from './CartSideBar';
import React, { useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import client from './Client';
import ProductList from './ProductList';
import CheckoutForm from './checkoutForm';
import CategorySubheader from './categorySubHeader';

function App() {

  const [searchKey, setsearchKey] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [total , setTotal] = useState(999999);


  const onSearchClick = (e) => {
    setsearchKey(e.current.value);
  };

  const onLogoClick = (key) => {
    setsearchKey(key);
  };



  const handleSearchChange = (e) => {
    return e.target.value;
  };


  const addToCart = (product, quantity) => {
    var result = cartItems.find(citem => {
      return citem.code === product.code;
    })
    if(result){
      const newCart = cartItems.filter(item => item.code !== product.code );
      quantity = result.quantity + quantity;
      console.log(quantity)
      const newItem = { ...product, quantity };
      setCartItems([...newCart, newItem]);
    } else 
    {
      const newItem = { ...product, quantity };
      setCartItems([...cartItems, newItem]);
    }

  };


  // Function to remove an item from the cart
  const removeItemFromCart = (itemToRemove) => {
    const updatedCart = cartItems.filter((item) => item.code !== itemToRemove.code);
    setCartItems(updatedCart);
  };

  // Function to handle the checkout process
  const handleCheckout = (totalPrice) => {
    // Implement your checkout logic here
    setIsModalOpen(!isModalOpen)
    setTotal(totalPrice);
    setShowCart(false)
  };

  


  const updateShowCart = () => {
    setShowCart(!showCart)
  }

  const filterProductByCategory = (key) => {
    setsearchKey(key)
  }


  return (

    <div className="App">

      <Header cartcount={cartItems.length} cartDisp={updateShowCart} key={searchKey} onSearchChange={handleSearchChange} onSearchClick={onSearchClick} onLogoClick={onLogoClick} ></Header>
      <CategorySubheader onSearchClick={filterProductByCategory} > </CategorySubheader>
      <div style={{padding:"10px"}}> Actual pricing will be released soon!!! </div>
      
      <ApolloProvider client={client}>
        <ProductList addToCart={addToCart} searchkey={searchKey} />

        {showCart ?
          <CartSidebar
            cartItems={cartItems}
            onRemoveItem={removeItemFromCart}
            onCheckout={handleCheckout}
            addToCart={addToCart}
            showCart={showCart}
            setShowCart={setShowCart}
          />
          : <></>}

          {
            isModalOpen ?
               <CheckoutForm setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} cartItems={cartItems} total={total}></CheckoutForm>
               : <div>
               </div>
          }


      </ApolloProvider>










    </div>
  );
}

export default App;
