import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const httpLink = new HttpLink({
  uri: 'https://pataki-angadi.hasura.app/v1/graphql',
  headers : {
    "x-hasura-admin-secret" : "8t7DDztjiIxZI17kEQ5pvMiMZv8aNkFq0b5lCpW7Fd5qwIQnY2Xgpkp9DdSVLZgW",
    "content-type" : "application/json"
  }
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export default client;
