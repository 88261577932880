// src/ProductList.js
import React from 'react';
import ProductCard from './ProductCard';
import {GET_PRODUCTS_BY_FILTER } from './queries';
import { useQuery } from '@apollo/client';


function ProductList({addToCart , searchkey}) {

    const filler = '%' + searchkey + '%';

    const { loading, error, data } = useQuery(GET_PRODUCTS_BY_FILTER, {
        variables: {
            name : filler
        }
      });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
     
      
    const products = data.products || [];  

    return (
        <div>
            <div className="product-list">
                {products.map((product) => (
                    <ProductCard
                        key={product.id}
                        product={product}
                        onAddToCart={addToCart}
                    />
                ))}
            </div>
        </div>
    );
}

export default ProductList;
