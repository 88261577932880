import React from "react";
import { v4 as uuid } from 'uuid';


export default function CartItemsList({ cartItems, onRemoveItem , addToCart}) {

    const incrementQuantity = (item) => {
        addToCart(item, 1)
    }

    const decrementQuantity = (item) => {
        if(item.quantity === 1){
            onRemoveItem(item)
        }else{
            addToCart(item, -1)
        }
    }


    const cartItemsList = cartItems.map((item) => (

        <div key={uuid().slice(0, 8)} className="cart-item">
            <img src={item.image} alt={item.name} className="cart-item-image" />
            <div className="cart-item-details">
                <span className="cart-item-name"> {item.name}</span>
                <div className="cart-item-price"> <span> Price   </span>  &#8377; {item.price}</div>
                <div className="quantity-control">
                    <button onClick={e => decrementQuantity(item)}>-</button>
                    <span>  {item.quantity} </span> 
                    <button onClick={e => incrementQuantity(item)}>+</button>
                </div>
                <div className="cart-item-quantity">
                    <button style={{ paddingTop: "10px" }} onClick={() => onRemoveItem(item)}>Remove</button>
                </div>
            </div>
        </div>
    ));




    return cartItemsList;
}

