
import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
query getProducts {
    products:product {
      id
      name
      size
      price:code
    }
  }  
`;

export const GET_PRODUCTS_BY_FILTER = gql`
query getProductsByFilter($name : String!) {
    products:product(where: {name: {_ilike: $name }} , order_by: {name: asc}) {
      name
      size
      code
      image
      price
    }
  }
`;
