import React from 'react';

const CategorySubheader = ({onSearchClick}) => {
  return (
    <div className="category">
            
            <div onClick={() => onSearchClick('sparklers')} >Sparklers</div>
            <div onClick={() => onSearchClick('flower')} >Flower pots</div>
            <div onClick={() => onSearchClick('chakkars')} >Chakkars</div>
            <div onClick={() => onSearchClick('rockets')} >Rockets</div>
            <div onClick={() => onSearchClick('bombs')} >Bombs</div>
    </div>
  );
};

export default CategorySubheader;
