import React, { useState } from 'react';

const CheckoutForm = ({setIsModalOpen,isModalOpen , cartItems , total}) => {
  
  const [formData, setFormData] = useState({
    mobileNumber: '',
    email: '',
    address: '',
    pincode: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send the data to a server
    console.log('Form submitted:', formData);
    // Close the modal after submission
    setIsModalOpen(false);
  };

  return (
    <div>

      {isModalOpen && (
        <div className="modal">   
          <div className="modal-content">

            <span className="close" onClick={() => setIsModalOpen(false)}>
              &times;
            </span>
            <div style={{fontWeight:'bold' , color:'burlywood' , paddingBottom:'20px' , paddingTop:'40px'}}>  
                   ------------Checkout-------------
            </div>
            <div style={{display:'flex' , flexDirection:'column', justifyContent:'center', textAlign:'center'}}>
                  <div style={{paddingRight:'46px'}}>
                    <span style={{fontWeight:'bold' , color:'burlywood' , paddingRight:'10px' }}> Cart Items  : </span> {cartItems.length}
                  </div>
                  <div>
                    <span style={{fontWeight:'bold' , color:'burlywood' ,  paddingRight:'10px' }}> Total Cost  :  </span>  &#8377;{total}
                  </div>
            </div>
            <form onSubmit={handleSubmit} style={{display:"flex", alignItems:"center"}}>
              <div  className="mobile" >
                <label  htmlFor="mobileNumber">Mobile</label>
                <input
                  style={{width:"180px" , height:"20px"}}
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="email" >
                <label  htmlFor="email">Email</label>
                <input
                  style={{width:"180px" , height:"20px"}}
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="pincode" >
                <label   htmlFor="pincode">Pincode</label>
                <input
                  style={{width:"180px" , height:"20px"}}
                  type="text"
                  id="pincode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
              </div>
              <div className="address">
                <label  htmlFor="address">Address</label>
                <textarea
                  style={{width:"180px" , height:"50px"}}
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
              <div style={{padding:"20px", color:"grey"}}>
                 Once the order is confirmed we will be contacting you on whatsapp
              </div>
              <button type="submit">Place Order</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;
